// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-hot-16-challenge-2-chart-en-jsx": () => import("./../../../src/pages/hot16challenge2-chart-en.jsx" /* webpackChunkName: "component---src-pages-hot-16-challenge-2-chart-en-jsx" */),
  "component---src-pages-hot-16-challenge-2-chart-jsx": () => import("./../../../src/pages/hot16challenge2-chart.jsx" /* webpackChunkName: "component---src-pages-hot-16-challenge-2-chart-jsx" */),
  "component---src-pages-hot-16-challenge-2-en-jsx": () => import("./../../../src/pages/hot16challenge2-en.jsx" /* webpackChunkName: "component---src-pages-hot-16-challenge-2-en-jsx" */),
  "component---src-pages-hot-16-challenge-2-jsx": () => import("./../../../src/pages/hot16challenge2.jsx" /* webpackChunkName: "component---src-pages-hot-16-challenge-2-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-octocyclop-jsx": () => import("./../../../src/pages/octocyclop.jsx" /* webpackChunkName: "component---src-pages-octocyclop-jsx" */),
  "component---src-pages-themetest-jsx": () => import("./../../../src/pages/themetest.jsx" /* webpackChunkName: "component---src-pages-themetest-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

